export const index_name = process.env.GATSBY_ALGOLIA_INDEX_NAME;

export const currency = process.env.GATSBY_CURRENCY;
export const defaultArea = process.env.GATSBY_DEFAULT_AREA ? process.env.GATSBY_DEFAULT_AREA : "London";
export const defaultArea_Slug = defaultArea.toLowerCase().replace(/ /g,'-');

export const country = defaultArea;

export const defaultArea_Newhomes = process.env.GATSBY_DEFAULT_AREA_NEWHOMES ? process.env.GATSBY_DEFAULT_AREA_NEWHOMES : "London";
export const defaultArea_Newhomes_Slug = defaultArea_Newhomes.toLowerCase().replace(/ /g,'-');

export const site_name = process.env.GATSBY_SITE_NAME;
export const Bugherd_API_Key = "";
export const hasMyaccount = (process.env.GATSBY_MYACCOUNT || null) === '1' ? true : false;


export const IsAllAgentReview = true;

export const site_bviewing_agent_id = "";

export const Default_View = "List"

export const epdqID = "";
export const shasign_pass = "";

export const footerVatDetails = "Beals Estate Agents Ltd Is Registered In England<br />Registered office address: 70 St. Mary Axe, London, EC3A 8BE.<br />Registered company number: 03614952. VAT Number: 213449231<br />Part of Lomond";

export const Mail_Vars = {
    fb_link: "https://www.facebook.com/profile.php?id=100066783788280",
    tw_link: "",
    company_phone: "0161 941 4111",
    available_time: "We're available from 09:00 - 18:00 Monday to Friday, Saturday: 09.00 - 13:00.",
    address: "Unit 1, Fulcrum 2, Solent Way, Whiteley, PO15 7FN."
}

export const PropertySearchURL = {
    residential: {
        sales:"/property/for-sale/",
        lettings:"/property/to-rent/",
        newhomes: "/new-homes/for-sale/",
    },
    commercial: {
        sales:"/commercial-property/for-sale/",
        lettings:"/commercial-property/to-rent/",
    },
    auction: {
        sales:"/auctions/for-sale/"
    }
}

export const PropertyDetailsURL = {
    residential: {
        sales:"/property-for-sale/",
        lettings:"/property-to-rent/",
        newhomes:"/new-homes-for-sale/"
    },
    commercial: {
        sales:"/commercial-property-for-sale/",
        lettings:"/commercial-property-to-rent/",
    },
    auction: {
        sales:"/auction-for-sale/"
    }
}

export const SearchTitle = {
    residential: {
        sales:"Properties for sale",
        lettings:"Properties to rent",
        newhomes:"New developments",
    },
    commercial: {
        sales:"Commercial properties for sale",
        lettings:"Commercial properties to rent",
    },
    auction: {
        sales:"Auction properties for sale"
    }
}

export const PopularSearch_Areas = [ 
    { value: "hampshire", label: "Hampshire" },
    { value: "fareham", label: "Fareham" },
    { value: "horndean", label: "Horndean" },
    { value: "whiteley", label: "Whiteley" }   
]

export const PopularSearch_Types = [ 
    { value: "house", label: "Houses" },
    { value: "apartment", label: "Apartments" },
    { value: "bungalow", label: "Bungalows" },
    { value: "maisonette", label: "Maisonettes" }   
]

export const sortByList = [ 
    { value: "HighestPrice", label: "Highest Price" },
    { value: "LowestPrice", label: "Lowest Price" },
    { value: "Newest", label: "Newest" },
    { value: "Oldest", label: "Oldest" } 
]

export const SearchType = {
    sales:"Buy",
    lettings:"Rent",
    newhomes:"New homes"
}

export const FurnishedType = [    
    { value: '', label: 'All' },
    { value: 'furnished', label: 'Furnished' },
    { value: 'unfurnished', label: 'Unfurnished' },
    { value: 'part-furnished', label: 'Part furnished' },
]

export const FormsSubject = {
    book_a_valuation_sell:{
        admin_subject:"New Vendor Enquiry",
        user_subject:"Your recent enquiry"
    },
    book_a_valuation_let:{
        admin_subject:"New Landlord Enquiry",
        user_subject:"Your recent enquiry"
    },
    book_a_viewing:{
        admin_subject:"Book a Viewing",
        user_subject:"Your viewing request"
    },
    contact_branch:{
        admin_subject:"Branch | Contact",
        user_subject:"Branch | Your recent enquiry"
    },
    contact_staff:{
        admin_subject:"Staff | Contact",
        user_subject:"Staff | Your recent enquiry"
    },
    get_in_touch:{
        admin_subject:"Contact",
        user_subject:"Your recent enquiry"
    },
    newsletter:{ 
        admin_subject:"Newsletter Subscription",
        user_subject:"Thank you for subscribing"
    },
    instant_valuation:{
        admin_subject:"Valuation Request",
        user_subject:"Your valuation request"
    },
    send_to_friend:{
        admin_subject:"Send to a friend",
        user_subject:"Send to a friend"
    }
}

export const leadooPageSlot = {
    sell_property:"Sellers",
    landlords:"Landlords",
    sales:"Sale listings",
    lettings:"Rental listings",
    branch_finder:"Contact branch page",
    general_enquiry:"General enquiry",
    valuation:"Valuation",
    home:"Valuation"
}